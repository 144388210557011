import './conversation-center.scss';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Loader } from 'concert-ui-library';
import { MenuItem, MenuItemCommandEvent } from 'primereact/menuitem';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useSelector } from 'react-redux';
import { ConversationContext, MessageForm, MessageSender } from './message-sender';
import { ConversationViewer } from './conversation-viewer';
import { NotificationContext } from '../../notification-context';
import {
    Conversation,
    ConversationInput,
    SendConversationMessageInput,
    useCreateConversationMutation,
    useLazyGetConversationQuery,
    useMarkConversationMessagesOpenedMutation,
    useSendConversationMessageMutation,
} from '../../services/graphql/generated';
import { ROUTE_PARAMS, ROUTES } from '../../constants';
import { ErrorConstants } from '../error/constants';
import {
    EPISODE_INACTIVE_ERROR_MESSAGE,
    EPISODE_INACTIVE_WARNING_MESSAGE,
    ERROR_CODES,
    MESSAGE_SUCCESSFULLY_SUBMITTED,
    NOT_ALLOWED_TO_SEND_MESSAGES,
    PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE,
    SEND_SECURE_MESSAGES_CUSTOM_PERMISSION,
} from './constants';
import { User } from '../user/slice';
import { selectUser } from '../user/selector';
import { selectIsSessionEndedDialogVisible } from '../auth/selector';

export const ConversationCenter: FunctionComponent = () => {
    const newConversationTitle = 'New Conversation';
    const placeholderErrorMessage = ErrorConstants.GenericErrorMessage;
    const placeholderSendMessageError = ErrorConstants.GenericErrorMessage;

    const history = useHistory();
    const currentLocation = useLocation();
    const { state } = currentLocation as { state: ConversationContext };
    const notificationContext = useContext(NotificationContext);
    const { episodeId, conversationId, messageId } = QueryString.parse(currentLocation.search);
    const user: User = useSelector(selectUser) as User;
    const sessionEnded = useSelector(selectIsSessionEndedDialogVisible);
    const [canSendMessage, setCanSendMessage] = useState<boolean>(true);
    const [isNewConversation, setIsNewConversation] = useState<boolean>(true);
    const [conversationState, setConversationState] = useState<Conversation | null>(null);
    const [sendMessageFormData, setSendMessageFormData] = useState<MessageForm | null>(null);
    const [conversationQuery, { data: conversation, error: conversationError, isFetching }] =
        useLazyGetConversationQuery();
    const [
        createConversation,
        {
            isLoading: isCreationInProgress,
            isSuccess: creationSucceeded,
            error: creationError,
            data: createConversationResponse,
        },
    ] = useCreateConversationMutation();
    const [markConversationAsOpened] = useMarkConversationMessagesOpenedMutation();

    const [
        sendMessage,
        {
            data: sendMessageResponse,
            error: sendMessageError,
            isSuccess: messageReplySucceeded,
            isLoading: isMessageSendingInProgress,
        },
    ] = useSendConversationMessageMutation();

    const isBusy = () => isFetching || isCreationInProgress || isMessageSendingInProgress;
    const shouldClearForm = () => !sessionEnded && (creationSucceeded || messageReplySucceeded);

    useEffect(() => {
        const getConversation = async () => {
            const payload = await conversationQuery({
                request: {
                    conversationId: conversationId as string,
                    messageId: messageId as string,
                },
            }).unwrap();
            const latestMessageCreatedDateUtc =
                payload?.getConversation?.conversation?.messages?.[0]?.createDate ?? null;
            // Use of viewing conversations via messageId is more for internal use, so don't mark as opened
            if (!messageId && latestMessageCreatedDateUtc) {
                await markConversationAsOpened({
                    input: {
                        conversationId: conversationId as string,
                        lastOpenedMessageCreateDate: latestMessageCreatedDateUtc,
                    },
                });
            }
        };
        if ((messageId || conversationId) && !isFetching && !conversation && !conversationError) {
            getConversation();
        }
    }, []);

    const handleConversationCreation = async (formData: MessageForm) => {
        const creationInput: ConversationInput = {
            episodeId: formData.episode?.id as string,
            message: {
                clinicalNotes: formData.clinicalNotes,
                message: formData.message,
                minutes: formData.minutes,
                recipientId: formData.episode?.patientId as string,
            },
            topic: formData.topic as string,
        };
        setSendMessageFormData(formData);
        await createConversation({ input: creationInput });
    };
    const handleMessageSending = async (formData: MessageForm) => {
        const id = conversationId ?? conversationState?.id;
        const recipientId = conversation
            ? conversation.getConversation?.conversation?.patientId
            : conversationState?.patientId;
        const inputMessage: SendConversationMessageInput = {
            conversationId: id as string,
            message: {
                clinicalNotes: formData.clinicalNotes,
                message: formData.message,
                minutes: formData.minutes,
                recipientId: recipientId as string,
            },
        };
        setSendMessageFormData(formData);
        await sendMessage({ input: inputMessage });
    };

    const messageHandlerSubmission = async (formData: MessageForm) => {
        if (formData.isValid) {
            !conversationState ? await handleConversationCreation(formData) : await handleMessageSending(formData);
        } else {
            notificationContext?.showError('Please fill in the required fields for the message');
        }
    };

    const goHome = (_: MenuItemCommandEvent): void => {
        history.push(`${ROUTES.MESSAGING}?${ROUTE_PARAMS.EPISODE_ID}=${episodeId}`, {
            refresh: true,
        });
    };

    const getHomeBreadcrumb = (): MenuItem => {
        const homeItem: MenuItem = {
            id: 'home',
            label: 'Messaging',
            className: 'unclickable',
        };
        return homeItem;
    };

    const getConversationTopicFromLocationState = (): string | null => {
        if (conversationState) {
            return conversationState?.topic ?? '';
        }

        if (state) {
            return state.topic ?? '';
        }

        return null;
    };

    const getConversationEpisodeNameFromLocationState = (): string | null => {
        if (conversationState) {
            return conversationState?.episodeName ?? '';
        }

        if (state) {
            return state.episodeName ?? '';
        }

        return null;
    };

    const getNavigationModel = (): MenuItem[] => {
        const navigationModel = new Array<MenuItem>();
        const conversationEpisodeName = getConversationEpisodeNameFromLocationState();
        const conversationTopic = getConversationTopicFromLocationState();

        if (conversationEpisodeName) {
            navigationModel.push({
                id: 'episode',
                label: conversationEpisodeName,
                command: goHome,
            });
        }
        if (isNewConversation && !conversationId) {
            navigationModel.push({
                id: 'conversation',
                label: newConversationTitle,
                className: 'unclickable active',
            });
        } else if (conversationTopic) {
            navigationModel.push({
                id: 'conversation',
                label: conversationTopic,
                className: 'unclickable active',
            });
        }
        return navigationModel;
    };

    const noCommunicationErrorHandler = (message: string) => {
        if (message.includes(ERROR_CODES.PATIENT_CANT_RECEIVE_COMMUNICATIONS)) {
            notificationContext?.showError(PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE);
            return;
        }
        if (message.includes(ERROR_CODES.INACTIVE_EPISODE)) {
            notificationContext?.showError(EPISODE_INACTIVE_ERROR_MESSAGE);
            return;
        }
        if (message.includes(ERROR_CODES.USER_WITHOUT_PERMISSION)) {
            notificationContext?.showError(NOT_ALLOWED_TO_SEND_MESSAGES);
            return;
        }
        notificationContext?.showError(placeholderSendMessageError);
    };
    useEffect(() => conversationError && notificationContext?.showError(placeholderErrorMessage), [conversationError]);
    useEffect(() => creationError && noCommunicationErrorHandler(creationError.message), [creationError]);
    useEffect(() => sendMessageError && noCommunicationErrorHandler(sendMessageError.message), [sendMessageError]);

    useEffect(() => {
        if (conversation) {
            setIsNewConversation(false);
            const communicationInfo = conversation?.getConversation?.patientCommunicationInformation;
            const isCommunicationClinicianSameAsUser =
                conversation?.getConversation?.conversation?.clinicianId === user.id;
            if (communicationInfo && !communicationInfo.canReceiveCommunications) {
                notificationContext?.showWarning(
                    communicationInfo.hasActiveEpisode
                        ? PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE
                        : EPISODE_INACTIVE_WARNING_MESSAGE,
                );
                setCanSendMessage(false);
            } else {
                setCanSendMessage(
                    user.sfCustomPermissions.includes(SEND_SECURE_MESSAGES_CUSTOM_PERMISSION) &&
                        isCommunicationClinicianSameAsUser,
                );
            }
        }
        if (
            (!conversationState && creationSucceeded && createConversationResponse) ||
            (messageReplySucceeded && sendMessageResponse)
        ) {
            notificationContext.showSuccess(MESSAGE_SUCCESSFULLY_SUBMITTED);
            history.push(`${ROUTES.MESSAGING}?${ROUTE_PARAMS.EPISODE_ID}=${episodeId}`);
            return;
        }
        if (!conversationState && conversation) {
            setConversationState(conversation.getConversation!.conversation!);
        }
    }, [conversation, createConversationResponse, sendMessageResponse]);

    return (
        <div>
            <div className="app-container message-center-container">
                <BreadCrumb model={getNavigationModel()} home={getHomeBreadcrumb()} />
                {isBusy() ? (
                    <Loader />
                ) : (
                    <ConversationViewer conversation={conversationState}>
                        <MessageSender
                            isNewConversation={conversationState === null}
                            handleMessageSubmission={messageHandlerSubmission}
                            disabled={!canSendMessage}
                            isFormVisible={conversationId === undefined && messageId === undefined}
                            formValue={sendMessageFormData}
                            shouldClearForm={shouldClearForm()}
                        />
                    </ConversationViewer>
                )}
            </div>
        </div>
    );
};
