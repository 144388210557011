export const PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE =
    'The patient cannot be messaged: No communication method available.';
export const EPISODE_INACTIVE_ERROR_MESSAGE =
    'Your message was not saved. New messages are not allowed for closed episodes.';
export const NOT_ALLOWED_TO_SEND_MESSAGES =
    'Your message was not saved. Please contact support for permission to send messages.';
export const EPISODE_INACTIVE_WARNING_MESSAGE = 'New messages are not allowed for closed episodes.';
export const ERROR_CODES = {
    PATIENT_CANT_RECEIVE_COMMUNICATIONS: '-0001',
    INACTIVE_EPISODE: '-0002',
    USER_WITHOUT_PERMISSION: '-0003',
};
export const SEND_SECURE_MESSAGES_CUSTOM_PERMISSION = 'Secure_Messaging_Edit';
export const MESSAGE_SUCCESSFULLY_SUBMITTED = 'Message and Time Entry saved';
